/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import { put, takeLatest, call, all, select, delay } from "redux-saga/effects";
import queryString from "query-string";
import ShortUniqueId from "short-unique-id";

import {
  getApiToken,
  postApiToken,
  putApiToken,
  deleteApiToken,
} from "../../../../redux/apis";
import apiUrl from "../../../../configs/urls";
import { actions as authActions } from "../../Auth/_redux/authRedux";
import { actions as generalActions } from "../../../../redux/generalReducer";
import { actions as feedbackActions } from "../../FeedbackSettings/redux/feedbackSettingRedux";

const uid = new ShortUniqueId();

export const actionTypes = {
  SET_LIST_LOADING: "SET_LIST_LOADING",
  GET_CLIENTS_LIST: "GET_CLIENTS_LIST",
  SET_CLIENTS_LIST: "SET_CLIENTS_LIST",
  CHANGE_CURRENT_PAGE: "CHANGE_CURRENT_PAGE_CLIENT",
  CLEAR_LIST: "CLEAR_LIST_CLIENT",
  CHANGE_SORT: "CHANGE_SORT_CLIENT",
  CHANGE_CLIENTS_FILTER_DATA: "CHANGE_CLIENTS_FILTER_DATA",
  CLEAR_CLIENTS_FILTER_DATA: "CLEAR_CLIENTS_FILTER_DATA",
  CLEAR_ALL: "CLEAR_ALL_CLIENT",
  SAVE_CLIENT_DATA: "SAVE_CLIENT_DATA",
  SAVE_CLIENT_DATA_LOADING: "SAVE_CLIENT_DATA_LOADING",
  SAVE_CLIENT_DATA_FAILED: "SAVE_CLIENT_DATA_FAILED",
  SAVE_CLIENT_DATA_SUCCESS: "SAVE_CLIENT_DATA_SUCCESS",
  CLOSE_CLIENT_DIALOG: "CLOSE_CLIENT_DIALOG",
  CHANGE_CLIENT_DATA: "CHANGE_CLIENT_DATA",
  GET_CLIENT_DATA: "GET_CLIENT_DATA",
  GET_CLIENT_LOADING: "GET_CLIENT_LOADING",
  GET_CLIENT_FAILED: "GET_CLIENT_FAILED",
  GET_CLIENT_SUCCESS: "GET_CLIENT_SUCCESS",
  UPDATE_CLIENT_DATA: "UPDATE_CLIENT_DATA",
  UPDATE_CLIENT_DATA_LOADING: "UPDATE_CLIENT_DATA_LOADING",
  UPDATE_CLIENT_DATA_FAILED: "UPDATE_CLIENT_DATA_FAILED",
  UPDATE_CLIENT_DATA_SUCCESS: "UPDATE_CLIENT_DATA_SUCCESS",
  GET_CLIENT_API_ACCESS_KEY: "GET_CLIENT_API_ACCESS_KEY",
  CLIENT_API_ACCESS_KEY: "CLIENT_API_ACCESS_KEY",
  GET_CLIENT_API_LOADING: "GET_CLIENT_API_LOADING",
  GET_CLIENT_API_FAILED: "GET_CLIENT_API_FAILED",
  GET_CLIENT_API_SUCCESS: "GET_CLIENT_API_SUCCESS",
  REGENERATE_ACCESS_KEY: "REGENERATE_ACCESS_KEY",
  CLIENT_RESOURCE_SEARCH: "CLIENT_RESOURCE_SEARCH",
  SET_RESOURCE_SUGGESSION: "SET_RESOURCE_SUGGESSION",
  ADD_CLIENT_RESOURCE: "ADD_CLIENT_RESOURCE",
  ADD_CLIENT_RESOURCE_SUCCESS: "ADD_CLIENT_RESOURCE_SUCCESS",
  ADD_CLIENT_RESOURCE_LOADING: "ADD_CLIENT_RESOURCE_LOADING",
  ADD_CLIENT_RESOURCE_FAILED: "ADD_CLIENT_RESOURCE_FAILED",
  SET_CLIENT_RESOURCE_LIST: "SET_CLIENT_RESOURCE_LIST",
  GET_CLIENT_RESOURCE_LIST: "GET_CLIENT_RESOURCE_LIST",
  GET_CLIENT_RESOURCE_SUCCESS: "GET_CLIENT_RESOURCE_SUCCESS",
  GET_CLIENT_RESOURCE_LOADING: "GET_CLIENT_RESOURCE_LOADING",
  GET_CLIENT_RESOURCE_FAILED: "GET_CLIENT_RESOURCE_FAILED",
  CHANGE_CURRENT_PAGE_RESOURCE: "CHANGE_CURRENT_PAGE_RESOURCE",
  CHANGE_SORT_RESOURCE: "CHANGE_SORT_RESOURCE",
  REMOVE_CLIENT_RESOURCE: "REMOVE_CLIENT_RESOURCE",
  RESOURCE_SET_PM: "RESOURCE_SET_PM",
  REMOVE_CLIENT_RESOURCE_LOADING: "REMOVE_CLIENT_RESOURCE_LOADING",
  RESOURCE_SET_PM_LOADING: "RESOURCE_SET_PM_LOADING",
  GET_CLIENT_SUBUSER: "GET_CLIENT_SUBUSER",
  SET_CLIENT_SUBUSER: "SET_CLIENT_SUBUSER",
  CHANGE_CURRENT_PAGE_SUBUSER: "CHANGE_CURRENT_PAGE_SUBUSER",
  CHANGE_SORT_SUBUSER: "CHANGE_SORT_SUBUSER",
  GET_CLIENT_SUBUSER_LOADING: "GET_CLIENT_SUBUSER_LOADING",
  RESEND_EMAIL: "RESEND_EMAIL",
  RESEND_EMAIL_LOADING: "RESEND_EMAIL_LOADING",
  CLIENT_NAME: "CLIENT_NAME",
  LOGIN_AS_CLIENT: "LOGIN_AS_CLIENT",
  LOGIN_AS_CLIENT_LOADING: "LOGIN_AS_CLIENT_LOADING",
  LOGIN_AS_CLIENT_SUCCESS: "LOGIN_AS_CLIENT_SUCCESS",
  LOGIN_AS_CLIENT_FAILED: "LOGIN_AS_CLIENT_FAILED",
  SET_ACCESS_TOKEN: "SET_ACCESS_TOKEN",
  CLEAR_LOGIN_AS_CLIENT_FLAGS: "CLEAR_LOGIN_AS_CLIENT_FLAGS",
  SET_FILTER: "SET_FILTER",
  SET_CURRENT_PAGE: "SET_CURRENT_PAGE_CLIENT",
  SAVE_SUBUSER_DETAILS: "SAVE_SUBUSER_DETAILS",
  SAVE_SUBUSER_DETAILS_LOADING: "SAVE_SUBUSER_DETAILS_LOADING",
  SAVE_SUBUSER_DETAILS_SUCCESS: "SAVE_SUBUSER_DETAILS_SUCCESS",
  SAVE_SUBUSER_DETAILS_FAILURE: "SAVE_SUBUSER_DETAILS_FAILURE",
  EDIT_SUBUSER_DETAILS: "EDIT_SUBUSER_DETAILS",
  EDIT_SUBUSER_DETAILS_LOADING: "EDIT_SUBUSER_DETAILS_LOADING",
  EDIT_SUBUSER_DETAILS_SUCCESS: "EDIT_SUBUSER_DETAILS_SUCCESS",
  EDIT_SUBUSER_DETAILS_FAILURE: "EDIT_SUBUSER_DETAILS_FAILURE",
  SAVE_UPDATED_SUBUSER_DETAILS: "SAVE_UPDATED_SUBUSER_DETAILS",
  SAVE_UPDATED_DETAILS_SUBUSER_LOADING: "SAVE_UPDATED_DETAILS_SUBUSER_LOADING",
  SAVE_UPDATED_DETAILS_SUBUSER_SUCCESS: "SAVE_UPDATED_DETAILS_SUBUSER_SUCCESS",
  SAVE_UPDATED_DETAILS_SUBUSER_FAILURE: "SAVE_UPDATED_DETAILS_SUBUSER_FAILURE",
  USER_TYPE_DATA: "USER_TYPE_DATA",
  USER_TYPE_DATA_LOADING: "USER_TYPE_DATA_LOADING",
  USER_TYPE_DATA_SUCCESS: "USER_TYPE_DATA_SUCCESS",
  USER_TYPE_DATA_FAILURE: "USER_TYPE_DATA_FAILURE",
  CLEAR_CLIENT_ACCOUNT_DETAILS: "CLEAR_CLIENT_ACCOUNT_DETAILS",
  GET_CLIENT_ACTIVITY_LOGS: "GET_CLIENT_ACTIVITY_LOGS",
  GET_CLIENT_ACTIVITY_LOADING: "GET_CLIENT_ACTIVITY_LOADING",
  SET_CLIENT_ACTIVITY: "SET_CLIENT_ACTIVITY",
  CHANGE_CURRENT_PAGE_ACTIVITY: "CHANGE_CURRENT_PAGE_ACTIVITY",
  CHANGE_SORT_ACTIVITY: "CHANGE_SORT_ACTIVITY",
  GET_CLIENT_ACTIVITY_FILTER: "GET_CLIENT_ACTIVITY_FILTER",
  SET_CLIENT_ACTIVITY_FILTER: "SET_CLIENT_ACTIVITY_FILTER",
  SET_CLIENT_ACTIVITY_UPDATED_BY: "SET_CLIENT_ACTIVITY_UPDATED_BY",
  SET_CLIENT_ACTIVITY_FUNCTION: "SET_CLIENT_ACTIVITY_FUNCTION",
  SET_CLIENT_ACTIVITY_START_DATE: "SET_CLIENT_ACTIVITY_START_DATE",
  SET_CLIENT_ACTIVITY_END_DATE: "SET_CLIENT_ACTIVITY_END_DATE",
  GET_ANOUNCEMENT_DATA: "GET_ANOUNCEMENT_DATA",
  SET_ANOUNCEMENT_DATA: "SET_ANOUNCEMENT_DATA",
  SET_ANOUNCEMENT_CLIENT_DROPDOWN: "SET_ANOUNCEMENT_CLIENT_DROPDOWN",
  UPDATE_ANOUNCEMENT_DATA: "UPDATE_ANOUNCEMENT_DATA",
  SET_STANDARD_CLIENTS: "SET_STANDARD_CLIENTS",
  SET_ENTERPRISE_CLIENTS: "SET_ENTERPRISE_CLIENTS",
  REOPEN_FEEDBACK: "REOPEN_FEEDBACK",
};

const initialClientsState = {
  clientsList: undefined,
  totalClientsRecords: undefined,
  listLoading: false,
  currentPage: 0,
  maxClientsPerPage: 10,
  clientsSortField: "id",
  clientsSortOrder: "desc",
  clientsFilterData: {
    name: "",
    company_name: "",
    email: "",
    status: null,
    is_sandbox: null,
    is_archieve: 0,
  },
  clientData: {
    name: "",
    company_name: "",
    client_type: "standard",
    email: "",
    phone_code: "us",
    phone: "",
    status: 0,
    id: "",
    max_revision_request: 0,
    is_archieve: 0,
    max_request_prioritisation: 0,
    is_allow_free_sample_design: 0,
    max_free_sample_design: 3,
    free_sample_designs: "",
  },
  saveClientDataLoading: false,
  saveClientDataSuccess: false,
  saveClientDataFailed: false,
  getClientLoading: false,
  getClientSuccess: false,
  getClientFailed: false,
  updateClientDataLoading: false,
  updateClientDataSuccess: false,
  updateClientDataFailed: false,
  clientApiAccessKey: "",
  getClientApiLoading: false,
  getClientApiSuccess: false,
  getClientApiFailed: false,
  resourceSuggession: [],
  resourceSuggessionArr: [],
  addClientResourceLoading: false,
  addClientResourceSuccess: false,
  addClientResourceFailed: false,
  clientResourceList: "",
  totalClientResourceRecords: "",
  getClientResourceLoading: false,
  getClientResourceSuccess: false,
  getClientResourceFailed: false,
  currentPageResource: 0,
  maxResourcePerPage: 10,
  clientsSortFieldResource: "id",
  clientsSortOrderResource: "desc",
  removeClientResourceLoading: false,
  setPMClientResourceLoading: false,
  clientSubuserList: "",
  totalClientSubuserRecords: "",
  currentPageSubuser: 0,
  maxSubuserPerPage: 10,
  clientsSortFieldSubuser: "id",
  clientsSortOrderSubuser: "desc",
  getClientSubuserLoading: false,
  resendEmailLoading: false,
  clientName: "",
  accessTokenForLoginAsClient: "",
  loginAsClientLoading: false,
  loginAsClientSuccess: false,
  loginAsClientFailed: false,
  isfilter: false,
  saveSubuserDataLoading: false,
  saveSubuserDataSuccess: false,
  saveSubuserDataFailed: false,
  editSubuserDataLoading: false,
  editSubuserDataSuccess: false,
  editSubuserDataFailed: false,
  subUserData: [],
  saveUpdatedSubuserDataLoading: false,
  saveUpdatedSubuserDataSuccess: false,
  saveUpdatedSubuserDataFailed: false,
  userTypeData: [],
  userTypeDataLoading: false,
  userTypeDataSuccess: false,
  userTypeDataFailed: false,
  clientActivityList: "",
  totalClientActivityRecords: "",
  currentPageActivity: 0,
  maxActivityPerPage: 10,
  clientsSortFieldActivity: "id",
  clientsSortOrderActivity: "desc",
  getClientActivityLoading: false,
  clientActivityFilter: "",
  clientActivityUpdatedBy: [],
  clientActivityFunction: [],
  clientActivityStartDate: "",
  clientActivityEndDate: "",
  anouncement: {
    is_to_all: 0,
    standard_clients: "",
    enterprise_clients: "",
    message: "",
    start_date: "",
    end_date: "",
    is_active: 0,
  },
  anouncementClientDropdown: "",
  standardClients: [],
  enterpriseClients: [],
};

export const reducer = (state = initialClientsState, action) => {
  switch (action.type) {
    case actionTypes.SET_LIST_LOADING: {
      return {
        ...state,
        listLoading: action.payload.flag,
      };
    }

    case actionTypes.SET_FILTER: {
      const { flag } = action.payload;
      return {
        ...state,
        isfilter: flag,
      };
    }

    case actionTypes.SET_CURRENT_PAGE: {
      const { data } = action.payload;
      return {
        ...state,
        currentPage: data,
      };
    }

    case actionTypes.CLEAR_LIST: {
      return {
        ...state,
        totalClientsRecords: undefined,
        clientsList: undefined,
      };
    }

    case actionTypes.SET_CLIENTS_LIST: {
      const { clientsData } = action.payload;
      const totalClientsRecords =
        state.isfilter && clientsData.recordsTotal
          ? clientsData.recordsFiltered
          : clientsData.recordsTotal;
      const clientsList = clientsData.data ? clientsData.data : undefined;
      return {
        ...state,
        totalClientsRecords,
        clientsList,
      };
    }

    case actionTypes.CHANGE_CURRENT_PAGE: {
      const { page } = action.payload;
      return {
        ...state,
        currentPage: page,
      };
    }

    case actionTypes.CHANGE_SORT: {
      const { field, order } = action.payload;
      return {
        ...state,
        clientsSortField: field,
        clientsSortOrder: order,
      };
    }

    case actionTypes.CHANGE_CLIENTS_FILTER_DATA: {
      const { clientsFilterData } = action.payload;
      return {
        ...state,
        clientsFilterData: clientsFilterData,
      };
    }
    case actionTypes.CLOSE_CLIENT_DIALOG: {
      return {
        ...state,
        clientData: {
          name: "",
          company_name: "",
          email: "",
          client_type: "standard",
          phone_code: "us",
          phone: "",
          max_revision_request: 0,
          is_archieve: 0,
          max_request_prioritisation: 0,
          is_allow_free_sample_design: 0,
          max_free_sample_design: 3,
          free_sample_designs: "",
        },
        saveClientDataLoading: false,
        saveClientDataSuccess: false,
        saveClientDataFailed: false,
      };
    }
    case actionTypes.CLEAR_ALL: {
      return {
        clientsList: undefined,
        totalClientsRecords: undefined,
        listLoading: false,
        currentPage: 0,
        maxClientsPerPage: 10,
        clientsSortField: "id",
        clientsSortOrder: "desc",
        clientsFilterData: {
          name: "",
          company_name: "",
          email: "",
          phone: "",
        },
        clientData: {
          name: "",
          company_name: "",
          email: "",
          phone_code: "us",
          client_type: "standard",
          phone: "",
          max_revision_request: 0,
          is_archieve: 0,
          max_request_prioritisation: 0,
          is_allow_free_sample_design: 0,
          max_free_sample_design: 3,
          free_sample_designs: "",
        },
        saveClientDataLoading: false,
        saveClientDataSuccess: false,
        saveClientDataFailed: false,
        isfilter: false,
      };
    }

    case actionTypes.CHANGE_CLIENT_DATA: {
      const { clientData } = action.payload;
      return {
        ...state,
        clientData: {
          name: clientData.name ? clientData.name : "",
          company_name: clientData.company_name ? clientData.company_name : "",
          email: clientData.email ? clientData.email : "",
          phone: clientData.phone ? clientData.phone : "",
          phone_code: clientData.phone_code ? clientData.phone_code : "us",
          client_type: clientData.client_type
            ? clientData.client_type
            : "standard",
          status: clientData.status ? clientData.status : 0,
          id: clientData.id ? clientData.id : "",
          max_revision_request: clientData.max_revision_request
            ? parseInt(clientData.max_revision_request) || 0
            : 0,
          is_sandbox: clientData.is_sandbox ? clientData.is_sandbox : 0,
          is_archieve: clientData.is_archieve ? clientData.is_archieve : 0,
          max_request_prioritisation: clientData.max_request_prioritisation
            ? parseInt(clientData.max_request_prioritisation) || 0
            : 0,
          is_allow_free_sample_design: clientData.is_allow_free_sample_design,
          max_free_sample_design: clientData.max_free_sample_design
            ? parseInt(clientData.max_free_sample_design) || 0
            : 0,
          free_sample_designs: clientData.free_sample_designs,
          is_all_tab_prioritisation_enabled:
            clientData.is_all_tab_prioritisation_enabled,
        },
      };
    }

    case actionTypes.SAVE_CLIENT_DATA_LOADING: {
      const { flag } = action.payload;
      return {
        ...state,
        saveClientDataLoading: flag,
      };
    }

    case actionTypes.SAVE_CLIENT_DATA_SUCCESS: {
      const { flag } = action.payload;
      return {
        ...state,
        saveClientDataSuccess: flag,
      };
    }

    case actionTypes.SAVE_CLIENT_DATA_FAILED: {
      const { flag } = action.payload;
      return {
        ...state,
        saveClientDataFailed: flag,
      };
    }

    case actionTypes.GET_CLIENT_SUCCESS: {
      const { flag } = action.payload;
      return {
        ...state,
        getClientSuccess: flag,
      };
    }

    case actionTypes.GET_CLIENT_LOADING: {
      const { flag } = action.payload;
      return {
        ...state,
        getClientLoading: flag,
      };
    }

    case actionTypes.GET_CLIENT_FAILED: {
      const { flag } = action.payload;
      return {
        ...state,
        getClientFailed: flag,
      };
    }

    case actionTypes.UPDATE_CLIENT_DATA_FAILED: {
      const { flag } = action.payload;
      return {
        ...state,
        updateClientDataFailed: flag,
      };
    }

    case actionTypes.UPDATE_CLIENT_DATA_SUCCESS: {
      const { flag } = action.payload;
      return {
        ...state,
        updateClientDataSuccess: flag,
      };
    }

    case actionTypes.UPDATE_CLIENT_DATA_LOADING: {
      const { flag } = action.payload;
      return {
        ...state,
        updateClientDataLoading: flag,
      };
    }

    case actionTypes.CLIENT_API_ACCESS_KEY: {
      const { clientApiAccessKey } = action.payload;
      return {
        ...state,
        clientApiAccessKey: clientApiAccessKey,
      };
    }

    case actionTypes.GET_CLIENT_API_LOADING: {
      const { flag } = action.payload;
      return {
        ...state,
        getClientApiLoading: flag,
      };
    }

    case actionTypes.GET_CLIENT_API_SUCCESS: {
      const { flag } = action.payload;
      return {
        ...state,
        getClientApiSuccess: flag,
      };
    }

    case actionTypes.GET_CLIENT_API_FAILED: {
      const { flag } = action.payload;
      return {
        ...state,
        getClientApiFailed: flag,
      };
    }
    case actionTypes.SET_RESOURCE_SUGGESSION: {
      const { resourceSuggession, resourceSuggessionArr } = action.payload;
      return {
        ...state,
        resourceSuggession: resourceSuggession,
        resourceSuggessionArr: resourceSuggessionArr,
      };
    }

    case actionTypes.ADD_CLIENT_RESOURCE_SUCCESS: {
      const { flag } = action.payload;
      return {
        ...state,
        addClientResourceSuccess: flag,
      };
    }

    case actionTypes.ADD_CLIENT_RESOURCE_LOADING: {
      const { flag } = action.payload;
      return {
        ...state,
        addClientResourceLoading: flag,
      };
    }

    case actionTypes.ADD_CLIENT_RESOURCE_FAILED: {
      const { flag } = action.payload;
      return {
        ...state,
        addClientResourceFailed: flag,
      };
    }

    case actionTypes.SET_CLIENT_RESOURCE_LIST: {
      const { clientResourceData } = action.payload;
      const totalClientResourceRecords = clientResourceData.recordsTotal
        ? clientResourceData.recordsTotal
        : undefined;
      const clientResourceList = clientResourceData.data
        ? clientResourceData.data
        : undefined;
      return {
        ...state,
        totalClientResourceRecords,
        clientResourceList,
      };
    }

    case actionTypes.CHANGE_CURRENT_PAGE_RESOURCE: {
      const { page } = action.payload;
      return {
        ...state,
        currentPageResource: page,
      };
    }

    case actionTypes.CHANGE_SORT_RESOURCE: {
      const { field, order } = action.payload;
      return {
        ...state,
        clientsSortFieldResource: field,
        clientsSortOrderResource: order,
      };
    }

    case actionTypes.GET_CLIENT_RESOURCE_FAILED: {
      const { flag } = action.payload;
      return {
        ...state,
        getClientResourceFailed: flag,
      };
    }

    case actionTypes.GET_CLIENT_RESOURCE_SUCCESS: {
      const { flag } = action.payload;
      return {
        ...state,
        getClientResourceSuccess: flag,
      };
    }

    case actionTypes.GET_CLIENT_RESOURCE_LOADING: {
      const { flag } = action.payload;
      return {
        ...state,
        getClientResourceLoading: flag,
      };
    }

    case actionTypes.REMOVE_CLIENT_RESOURCE_LOADING: {
      const { flag } = action.payload;
      return {
        ...state,
        removeClientResourceLoading: flag,
      };
    }

    case actionTypes.RESOURCE_SET_PM_LOADING: {
      const { flag } = action.payload;
      return {
        ...state,
        setPMClientResourceLoading: flag,
      };
    }

    case actionTypes.SET_CLIENT_SUBUSER: {
      const { clientSubuserData } = action.payload;
      const totalClientSubuserRecords = clientSubuserData.recordsTotal
        ? clientSubuserData.recordsTotal
        : undefined;
      const clientSubuserList = clientSubuserData.data
        ? clientSubuserData.data
        : undefined;
      return {
        ...state,
        totalClientSubuserRecords,
        clientSubuserList,
      };
    }

    case actionTypes.GET_CLIENT_SUBUSER_LOADING: {
      const { flag } = action.payload;
      return {
        ...state,
        getClientSubuserLoading: flag,
      };
    }

    case actionTypes.RESEND_EMAIL_LOADING: {
      const { flag } = action.payload;
      return {
        ...state,
        resendEmailLoading: flag,
      };
    }

    case actionTypes.CHANGE_CURRENT_PAGE_SUBUSER: {
      const { page } = action.payload;
      return {
        ...state,
        currentPageSubuser: page,
      };
    }

    case actionTypes.CHANGE_SORT_SUBUSER: {
      const { field, order } = action.payload;
      return {
        ...state,
        clientsSortFieldSubuser: field,
        clientsSortOrderSubuser: order,
      };
    }
    case actionTypes.CLIENT_NAME: {
      const { clientName } = action.payload;
      return {
        ...state,
        clientName: clientName,
      };
    }
    case actionTypes.LOGIN_AS_CLIENT_LOADING: {
      const { flag } = action.payload;
      return {
        ...state,
        loginAsClientLoading: flag,
      };
    }
    case actionTypes.LOGIN_AS_CLIENT_SUCCESS: {
      const { flag } = action.payload;
      return {
        ...state,
        loginAsClientSuccess: flag,
      };
    }
    case actionTypes.LOGIN_AS_CLIENT_FAILED: {
      const { flag } = action.payload;
      return {
        ...state,
        loginAsClientFailed: flag,
      };
    }
    case actionTypes.SET_ACCESS_TOKEN: {
      const { accessToken } = action.payload;
      return {
        ...state,
        accessTokenForLoginAsClient: accessToken,
      };
    }
    case actionTypes.CLEAR_LOGIN_AS_CLIENT_FLAGS: {
      return {
        ...state,
        loginAsClientLoading: false,
        loginAsClientSuccess: false,
        loginAsClientFailed: false,
      };
    }
    case actionTypes.SAVE_SUBUSER_DETAILS_LOADING: {
      return {
        ...state,
        saveSubuserDataLoading: true,
        saveSubuserDataSuccess: false,
        saveSubuserDataFailed: false,
      };
    }
    case actionTypes.SAVE_SUBUSER_DETAILS_SUCCESS: {
      return {
        ...state,
        saveSubuserDataLoading: false,
        saveSubuserDataSuccess: true,
        saveSubuserDataFailed: false,
      };
    }
    case actionTypes.SAVE_SUBUSER_DETAILS_FAILURE: {
      return {
        ...state,
        saveSubuserDataLoading: false,
        saveSubuserDataSuccess: false,
        saveSubuserDataFailed: true,
      };
    }
    case actionTypes.EDIT_SUBUSER_DETAILS_LOADING: {
      return {
        ...state,
        editSubuserDataLoading: true,
        editSubuserDataSuccess: false,
        editSubuserDataFailed: false,
      };
    }
    case actionTypes.EDIT_SUBUSER_DETAILS_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        editSubuserDataLoading: false,
        editSubuserDataSuccess: true,
        editSubuserDataFailed: false,
        subUserData: data,
      };
    }
    case actionTypes.EDIT_SUBUSER_DETAILS_FAILURE: {
      return {
        ...state,
        editSubuserDataLoading: false,
        editSubuserDataSuccess: false,
        editSubuserDataFailed: true,
      };
    }
    case actionTypes.SAVE_UPDATED_DETAILS_SUBUSER_LOADING: {
      return {
        ...state,
        saveUpdatedSubuserDataLoading: true,
        saveUpdatedSubuserDataSuccess: false,
        saveUpdatedSubuserDataFailed: false,
      };
    }
    case actionTypes.SAVE_UPDATED_DETAILS_SUBUSER_SUCCESS: {
      return {
        ...state,
        saveUpdatedSubuserDataLoading: false,
        saveUpdatedSubuserDataSuccess: true,
        saveUpdatedSubuserDataFailed: false,
      };
    }
    case actionTypes.SAVE_UPDATED_DETAILS_SUBUSER_FAILURE: {
      return {
        ...state,
        saveUpdatedSubuserDataLoading: false,
        saveUpdatedSubuserDataSuccess: false,
        saveUpdatedSubuserDataFailed: true,
      };
    }
    case actionTypes.USER_TYPE_DATA_LOADING: {
      return {
        ...state,
        userTypeDataLoading: true,
        userTypeDataSuccess: false,
        userTypeDataFailed: false,
      };
    }
    case actionTypes.USER_TYPE_DATA_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        userTypeDataLoading: false,
        userTypeDataSuccess: true,
        userTypeDataFailed: false,
        userTypeData: data,
      };
    }
    case actionTypes.USER_TYPE_DATA_FAILURE: {
      return {
        ...state,
        userTypeDataLoading: false,
        userTypeDataSuccess: false,
        userTypeDataFailed: true,
      };
    }
    case actionTypes.CLEAR_CLIENT_ACCOUNT_DETAILS: {
      return {
        ...state,
        clientResourceList: [],
        totalClientResourceRecords: 0,
        totalClientSubuserRecords: 0,
        clientSubuserList: [],
        clientActivityList: [],
      };
    }

    case actionTypes.GET_CLIENT_ACTIVITY_LOADING: {
      const { flag } = action.payload;
      return {
        ...state,
        getClientSubuserLoading: flag,
      };
    }

    case actionTypes.CHANGE_CURRENT_PAGE_ACTIVITY: {
      const { page } = action.payload;
      return {
        ...state,
        currentPageActivity: page,
      };
    }

    case actionTypes.CHANGE_SORT_ACTIVITY: {
      const { field, order } = action.payload;
      return {
        ...state,
        clientsSortFieldActivity: field,
        clientsSortOrderActivity: order,
      };
    }

    case actionTypes.SET_CLIENT_ACTIVITY: {
      const { clientActivityData } = action.payload;
      const totalClientActivityRecords = clientActivityData.recordsTotal
        ? clientActivityData.recordsTotal
        : undefined;
      const clientActivityList = clientActivityData.data
        ? clientActivityData.data
        : undefined;
      return {
        ...state,
        totalClientActivityRecords,
        clientActivityList,
      };
    }

    case actionTypes.SET_CLIENT_ACTIVITY_FILTER: {
      const { data } = action.payload;
      let temp1 = [];
      let temp2 = [];
      data.updated_by.forEach((element) => {
        temp1.push({ label: element.name, value: element.id });
      });
      data.functions.forEach((element) => {
        temp2.push({ label: element.name, value: element.id });
      });
      data["updated_by"] = temp1;
      data["functions"] = temp2;
      return {
        ...state,
        clientActivityFilter: data,
      };
    }

    case actionTypes.SET_CLIENT_ACTIVITY_UPDATED_BY: {
      return {
        ...state,
        clientActivityUpdatedBy: action.payload.data,
      };
    }

    case actionTypes.SET_CLIENT_ACTIVITY_FUNCTION: {
      return {
        ...state,
        clientActivityFunction: action.payload.data,
      };
    }

    case actionTypes.SET_CLIENT_ACTIVITY_START_DATE: {
      return {
        ...state,
        clientActivityStartDate: action.payload.data,
      };
    }

    case actionTypes.SET_CLIENT_ACTIVITY_END_DATE: {
      return {
        ...state,
        clientActivityEndDate: action.payload.data,
      };
    }

    case actionTypes.SET_ANOUNCEMENT_DATA: {
      return {
        ...state,
        anouncement: action.payload.data,
      };
    }

    case actionTypes.SET_ANOUNCEMENT_CLIENT_DROPDOWN: {
      let temp1 = [];
      let temp2 = [];
      action.payload.data.standard.forEach((element) => {
        temp1.push({ label: element.company_name, value: element.id });
      });
      action.payload.data.enterprise.forEach((element) => {
        temp2.push({ label: element.company_name, value: element.id });
      });
      return {
        ...state,
        anouncementClientDropdown: { standard: temp1, enterprise: temp2 },
      };
    }

    case actionTypes.SET_ENTERPRISE_CLIENTS: {
      const { data } = action.payload;
      return {
        ...state,
        enterpriseClients: data,
      };
    }

    case actionTypes.SET_STANDARD_CLIENTS: {
      const { data } = action.payload;
      return {
        ...state,
        standardClients: data,
      };
    }

    default:
      return state;
  }
};

export const actions = {
  setCurrentPage: (data) => ({
    type: actionTypes.SET_CURRENT_PAGE,
    payload: { data },
  }),
  setFilter: (flag) => ({
    type: actionTypes.SET_FILTER,
    payload: { flag },
  }),
  setListLoading: (flag) => ({
    type: actionTypes.SET_LIST_LOADING,
    payload: { flag },
  }),
  getClientsList: (sortField, sortOrder, page, length, accessToken) => ({
    type: actionTypes.GET_CLIENTS_LIST,
    payload: { page, length, accessToken, sortField, sortOrder },
  }),
  setClientsList: (clientsData) => ({
    type: actionTypes.SET_CLIENTS_LIST,
    payload: { clientsData },
  }),
  changeCurrentPage: (page, length, accessToken) => ({
    type: actionTypes.CHANGE_CURRENT_PAGE,
    payload: { page, accessToken, length },
  }),
  clearList: () => ({ type: actionTypes.CLEAR_LIST }),
  changeSort: (field, order, accessToken) => ({
    type: actionTypes.CHANGE_SORT,
    payload: { field, order, accessToken },
  }),
  changeClientsFilterData: (clientsFilterData) => ({
    type: actionTypes.CHANGE_CLIENTS_FILTER_DATA,
    payload: { clientsFilterData },
  }),
  clearClientsFilterData: (accessToken) => ({
    type: actionTypes.CLEAR_CLIENTS_FILTER_DATA,
    payload: { accessToken },
  }),
  clearAll: () => ({ type: actionTypes.CLEAR_ALL }),
  closeClientDialog: () => ({ type: actionTypes.CLOSE_CLIENT_DIALOG }),
  changeClientData: (clientData) => ({
    type: actionTypes.CHANGE_CLIENT_DATA,
    payload: { clientData },
  }),
  getClientData: (id, accessToken) => ({
    type: actionTypes.GET_CLIENT_DATA,
    payload: { id, accessToken },
  }),
  saveClient: (clientData, accessToken) => ({
    type: actionTypes.SAVE_CLIENT_DATA,
    payload: { clientData, accessToken },
  }),
  saveClientDataLoading: (flag) => ({
    type: actionTypes.SAVE_CLIENT_DATA_LOADING,
    payload: { flag },
  }),
  saveClientDataFailed: (flag) => ({
    type: actionTypes.SAVE_CLIENT_DATA_FAILED,
    payload: { flag },
  }),
  saveClientDataSuccess: (flag) => ({
    type: actionTypes.SAVE_CLIENT_DATA_SUCCESS,
    payload: { flag },
  }),
  getClientLoading: (flag) => ({
    type: actionTypes.GET_CLIENT_LOADING,
    payload: { flag },
  }),
  getClientSuccess: (flag) => ({
    type: actionTypes.GET_CLIENT_SUCCESS,
    payload: { flag },
  }),
  getClientFailed: (flag) => ({
    type: actionTypes.GET_CLIENT_FAILED,
    payload: { flag },
  }),
  updateClientDataLoading: (flag) => ({
    type: actionTypes.UPDATE_CLIENT_DATA_LOADING,
    payload: { flag },
  }),
  updateClientDataSuccess: (flag) => ({
    type: actionTypes.UPDATE_CLIENT_DATA_SUCCESS,
    payload: { flag },
  }),
  updateClientDataFailed: (flag) => ({
    type: actionTypes.UPDATE_CLIENT_DATA_FAILED,
    payload: { flag },
  }),
  updateClientData: (clientID, clientData, accessToken) => ({
    type: actionTypes.UPDATE_CLIENT_DATA,
    payload: { clientID, clientData, accessToken },
  }),
  getClientApiAccessKey: (clientID, accessToken) => ({
    type: actionTypes.GET_CLIENT_API_ACCESS_KEY,
    payload: { clientID, accessToken },
  }),
  setClientApiAccessKey: (clientApiAccessKey) => ({
    type: actionTypes.CLIENT_API_ACCESS_KEY,
    payload: { clientApiAccessKey },
  }),
  getClientApiFailed: (flag) => ({
    type: actionTypes.GET_CLIENT_API_FAILED,
    payload: { flag },
  }),
  getClientApiSuccess: (flag) => ({
    type: actionTypes.GET_CLIENT_API_SUCCESS,
    payload: { flag },
  }),
  getClientApiLoading: (flag) => ({
    type: actionTypes.GET_CLIENT_API_LOADING,
    payload: { flag },
  }),
  regenerateAccessKey: (clientID, accessToken) => ({
    type: actionTypes.REGENERATE_ACCESS_KEY,
    payload: { clientID, accessToken },
  }),
  setResourceSuggession: (resourceSuggession, resourceSuggessionArr) => ({
    type: actionTypes.SET_RESOURCE_SUGGESSION,
    payload: { resourceSuggession, resourceSuggessionArr },
  }),
  getResourceSuggession: (searchTerm, accessToken) => ({
    type: actionTypes.CLIENT_RESOURCE_SEARCH,
    payload: { searchTerm, accessToken },
  }),
  addClientResource: (clientID, designerID, isPM, accessToken) => ({
    type: actionTypes.ADD_CLIENT_RESOURCE,
    payload: { clientID, designerID, isPM, accessToken },
  }),
  addClientResourceFailed: (flag) => ({
    type: actionTypes.ADD_CLIENT_RESOURCE_FAILED,
    payload: { flag },
  }),
  addClientResourceSuccess: (flag) => ({
    type: actionTypes.ADD_CLIENT_RESOURCE_SUCCESS,
    payload: { flag },
  }),
  addClientResourceLoading: (flag) => ({
    type: actionTypes.ADD_CLIENT_RESOURCE_LOADING,
    payload: { flag },
  }),
  getClientResourceList: (
    clientID,
    sortField,
    sortOrder,
    page,
    length,
    accessToken
  ) => ({
    type: actionTypes.GET_CLIENT_RESOURCE_LIST,
    payload: { clientID, page, length, accessToken, sortField, sortOrder },
  }),
  setClientResourceList: (clientResourceData) => ({
    type: actionTypes.SET_CLIENT_RESOURCE_LIST,
    payload: { clientResourceData },
  }),
  changeCurrentPageResource: (clientID, page, length, accessToken) => ({
    type: actionTypes.CHANGE_CURRENT_PAGE_RESOURCE,
    payload: { clientID, page, accessToken, length },
  }),
  changeSortResource: (clientID, field, order, accessToken) => ({
    type: actionTypes.CHANGE_SORT_RESOURCE,
    payload: { clientID, field, order, accessToken },
  }),
  getClientResourceLoading: (flag) => ({
    type: actionTypes.GET_CLIENT_RESOURCE_LOADING,
    payload: { flag },
  }),
  getClientResourceSuccess: (flag) => ({
    type: actionTypes.GET_CLIENT_RESOURCE_SUCCESS,
    payload: { flag },
  }),
  getClientResourceFailed: (flag) => ({
    type: actionTypes.ADD_CLIENT_RESOURCE_FAILED,
    payload: { flag },
  }),
  removeClientResource: (clientID, resourceID, accessToken) => ({
    type: actionTypes.REMOVE_CLIENT_RESOURCE,
    payload: { clientID, resourceID, accessToken },
  }),
  removeClientResourceLoading: (flag) => ({
    type: actionTypes.REMOVE_CLIENT_RESOURCE_LOADING,
    payload: { flag },
  }),
  resourceSetPM: (clientID, resourceID, accessToken) => ({
    type: actionTypes.RESOURCE_SET_PM,
    payload: { clientID, resourceID, accessToken },
  }),
  getClientSubuserLoading: (flag) => ({
    type: actionTypes.GET_CLIENT_SUBUSER_LOADING,
    payload: { flag },
  }),
  setClientSubuserList: (clientSubuserData) => ({
    type: actionTypes.SET_CLIENT_SUBUSER,
    payload: { clientSubuserData },
  }),
  getClientSubuserList: (
    clientID,
    sortField,
    sortOrder,
    page,
    length,
    accessToken
  ) => ({
    type: actionTypes.GET_CLIENT_SUBUSER,
    payload: { clientID, page, length, accessToken, sortField, sortOrder },
  }),
  setPMClientResourceLoading: (flag) => ({
    type: actionTypes.RESOURCE_SET_PM_LOADING,
    payload: { flag },
  }),
  resendEmail: (clientID, accessToken) => ({
    type: actionTypes.RESEND_EMAIL,
    payload: { clientID, accessToken },
  }),
  resendEmailLoading: (flag) => ({
    type: actionTypes.RESEND_EMAIL_LOADING,
    payload: { flag },
  }),
  changeCurrentPageSubuser: (clientID, page, length, accessToken) => ({
    type: actionTypes.CHANGE_CURRENT_PAGE_SUBUSER,
    payload: { clientID, page, accessToken, length },
  }),
  changeSortSubuser: (clientID, field, order, accessToken) => ({
    type: actionTypes.CHANGE_SORT_SUBUSER,
    payload: { clientID, field, order, accessToken },
  }),
  setClientName: (clientName) => ({
    type: actionTypes.CLIENT_NAME,
    payload: { clientName },
  }),
  loginAsClient: (clientID, accessToken) => ({
    type: actionTypes.LOGIN_AS_CLIENT,
    payload: { clientID, accessToken },
  }),
  loginAsClientLoading: (flag) => ({
    type: actionTypes.LOGIN_AS_CLIENT_LOADING,
    payload: { flag },
  }),
  loginAsClientSuccess: (flag) => ({
    type: actionTypes.LOGIN_AS_CLIENT_SUCCESS,
    payload: { flag },
  }),
  loginAsClientFailed: (flag) => ({
    type: actionTypes.LOGIN_AS_CLIENT_FAILED,
    payload: { flag },
  }),
  setAccessToken: (accessToken) => ({
    type: actionTypes.SET_ACCESS_TOKEN,
    payload: { accessToken },
  }),
  clearLoginAsClientFlags: () => ({
    type: actionTypes.CLEAR_LOGIN_AS_CLIENT_FLAGS,
  }),
  addSubUserData: (
    clientID,
    name,
    email,
    phone_code,
    phone,
    userType,
    accessToken,
    is_request_prioritisation_allowed
  ) => ({
    type: actionTypes.SAVE_SUBUSER_DETAILS,
    payload: {
      clientID,
      name,
      email,
      phone_code,
      phone,
      userType,
      accessToken,
      is_request_prioritisation_allowed,
    },
  }),
  getSubUserData: (id, accessToken) => ({
    type: actionTypes.EDIT_SUBUSER_DETAILS,
    payload: { id, accessToken },
  }),
  saveUpdatedSubUserData: (
    id,
    name,
    email,
    phone_code,
    phone,
    status,
    userType,
    accessToken,
    is_request_prioritisation_allowed
  ) => ({
    type: actionTypes.SAVE_UPDATED_SUBUSER_DETAILS,
    payload: {
      id,
      name,
      email,
      phone_code,
      phone,
      status,
      userType,
      accessToken,
      is_request_prioritisation_allowed,
    },
  }),
  getUserTypeData: (accessToken) => ({
    type: actionTypes.USER_TYPE_DATA,
    payload: { accessToken },
  }),
  clearClientAccountDetails: () => ({
    type: actionTypes.CLEAR_CLIENT_ACCOUNT_DETAILS,
  }),
  getClientActivityLoading: (flag) => ({
    type: actionTypes.GET_CLIENT_ACTIVITY_LOADING,
    payload: { flag },
  }),
  setClientActivityList: (clientActivityData) => ({
    type: actionTypes.SET_CLIENT_ACTIVITY,
    payload: { clientActivityData },
  }),
  getClientActivityLogsList: (
    clientID,
    clientActivityUpdatedBy,
    clientActivityFunction,
    clientActivityStartDate,
    clientActivityEndDate,
    sortField,
    sortOrder,
    page,
    length,
    accessToken
  ) => ({
    type: actionTypes.GET_CLIENT_ACTIVITY_LOGS,
    payload: {
      clientID,
      clientActivityUpdatedBy,
      clientActivityFunction,
      clientActivityStartDate,
      clientActivityEndDate,
      page,
      length,
      accessToken,
      sortField,
      sortOrder,
    },
  }),
  changeCurrentPageActivity: (
    clientID,
    clientActivityUpdatedBy,
    clientActivityFunction,
    clientActivityStartDate,
    clientActivityEndDate,
    page,
    length,
    accessToken
  ) => ({
    type: actionTypes.CHANGE_CURRENT_PAGE_ACTIVITY,
    payload: {
      clientID,
      clientActivityUpdatedBy,
      clientActivityFunction,
      clientActivityStartDate,
      clientActivityEndDate,
      page,
      accessToken,
      length,
    },
  }),
  changeSortActivity: (
    clientID,
    clientActivityUpdatedBy,
    clientActivityFunction,
    clientActivityStartDate,
    clientActivityEndDate,
    field,
    order,
    accessToken
  ) => ({
    type: actionTypes.CHANGE_SORT_ACTIVITY,
    payload: {
      clientID,
      clientActivityUpdatedBy,
      clientActivityFunction,
      clientActivityStartDate,
      clientActivityEndDate,
      field,
      order,
      accessToken,
    },
  }),
  setClientActivityFilter: (data) => ({
    type: actionTypes.SET_CLIENT_ACTIVITY_FILTER,
    payload: { data },
  }),
  getClientActivityFilter: (accessToken) => ({
    type: actionTypes.GET_CLIENT_ACTIVITY_FILTER,
    payload: { accessToken },
  }),
  setClientActivityUpdatedBy: (data) => ({
    type: actionTypes.SET_CLIENT_ACTIVITY_UPDATED_BY,
    payload: { data },
  }),
  setClientActivityFunction: (data) => ({
    type: actionTypes.SET_CLIENT_ACTIVITY_FUNCTION,
    payload: { data },
  }),
  setClientActivityStartDate: (data) => ({
    type: actionTypes.SET_CLIENT_ACTIVITY_START_DATE,
    payload: { data },
  }),
  setClientActivityEndDate: (data) => ({
    type: actionTypes.SET_CLIENT_ACTIVITY_END_DATE,
    payload: { data },
  }),
  setAnoucementData: (data) => ({
    type: actionTypes.SET_ANOUNCEMENT_DATA,
    payload: { data },
  }),
  getAnouncementData: (accessToken) => ({
    type: actionTypes.GET_ANOUNCEMENT_DATA,
    payload: { accessToken },
  }),
  setAnoucementClientDropdown: (data) => ({
    type: actionTypes.SET_ANOUNCEMENT_CLIENT_DROPDOWN,
    payload: { data },
  }),
  updateAnnouncementData: (data, accessToken) => ({
    type: actionTypes.UPDATE_ANOUNCEMENT_DATA,
    payload: { data, accessToken },
  }),
  setSatndardClients: (data) => ({
    type: actionTypes.SET_STANDARD_CLIENTS,
    payload: { data },
  }),
  setEnterPriseClients: (data) => ({
    type: actionTypes.SET_ENTERPRISE_CLIENTS,
    payload: { data },
  }),
  reopenFeedback: (clientID, userId, accessToken) => ({
    type: actionTypes.REOPEN_FEEDBACK,
    payload: { clientID, userId, accessToken },
  }),
};

export function* saga() {
  yield takeLatest(actionTypes.CHANGE_CURRENT_PAGE, function* changePageSaga(
    action
  ) {
    const { clientsSortField, clientsSortOrder } = yield select(
      (state) => state.manageClients
    );
    const { page, length, accessToken } = action.payload;
    yield put(
      actions.getClientsList(
        clientsSortField,
        clientsSortOrder,
        page,
        length,
        accessToken
      )
    );
  });

  yield takeLatest(actionTypes.CHANGE_SORT, function* changeSortSaga(action) {
    let { field, order, accessToken } = action.payload;
    let { currentPage, maxClientsPerPage } = yield select(
      (state) => state.manageClients
    );
    yield put(
      actions.getClientsList(
        field,
        order,
        currentPage,
        maxClientsPerPage,
        accessToken
      )
    );
  });

  yield takeLatest(
    actionTypes.CLEAR_CLIENTS_FILTER_DATA,
    function* clearClientsFilterDataSaga(action) {
      let { accessToken } = action.payload;
      let {
        clientsSortField,
        clientsSortOrder,
        currentPage,
        maxClientsPerPage,
      } = yield select((state) => state.manageClients);
      let clientsFilterData = {
        name: "",
        company_name: "",
        email: "",
        status: null,
        is_sandbox: "",
        is_archieve: 0,
      };
      yield put(actions.changeClientsFilterData(clientsFilterData));
      yield put(actions.setFilter(false));

      yield put(
        actions.getClientsList(
          clientsSortField,
          clientsSortOrder,
          currentPage,
          maxClientsPerPage,
          accessToken
        )
      );
    }
  );

  yield takeLatest(actionTypes.GET_CLIENTS_LIST, getClientsListSaga);
  yield takeLatest(actionTypes.SAVE_CLIENT_DATA, saveClientSaga);
  yield takeLatest(actionTypes.GET_CLIENT_DATA, getClientDataSaga);
  yield takeLatest(actionTypes.UPDATE_CLIENT_DATA, updateClientDataSaga);
  yield takeLatest(
    actionTypes.GET_CLIENT_API_ACCESS_KEY,
    getClientApiAccessKeySaga
  );
  yield takeLatest(
    actionTypes.REGENERATE_ACCESS_KEY,
    regenerateApiAccessKeySaga
  );
  yield takeLatest(actionTypes.CLIENT_RESOURCE_SEARCH, resourceSearchSaga);
  yield takeLatest(actionTypes.ADD_CLIENT_RESOURCE, addClientResourceSaga);
  yield takeLatest(
    actionTypes.GET_CLIENT_RESOURCE_LIST,
    getClientResourceListSaga
  );

  yield takeLatest(
    actionTypes.CHANGE_CURRENT_PAGE_RESOURCE,
    function* changePageSaga(action) {
      const {
        clientsSortFieldResource,
        clientsSortOrderResource,
      } = yield select((state) => state.manageClients);
      const { clientID, page, length, accessToken } = action.payload;
      if (clientID) {
        yield put(
          actions.getClientResourceList(
            clientID,
            clientsSortFieldResource,
            clientsSortOrderResource,
            page,
            length,
            accessToken
          )
        );
      }
    }
  );

  yield takeLatest(actionTypes.CHANGE_SORT_RESOURCE, function* changeSortSaga(
    action
  ) {
    let { clientID, field, order, accessToken } = action.payload;
    let { currentPageResource, maxResourcePerPage } = yield select(
      (state) => state.manageClients
    );
    if (clientID) {
      yield put(
        actions.getClientResourceList(
          clientID,
          field,
          order,
          currentPageResource,
          maxResourcePerPage,
          accessToken
        )
      );
    }
  });
  yield takeLatest(
    actionTypes.REMOVE_CLIENT_RESOURCE,
    removeClientResourceSaga
  );
  yield takeLatest(actionTypes.RESOURCE_SET_PM, resourceSetPMSaga);
  yield takeLatest(actionTypes.GET_CLIENT_SUBUSER, getClientSubuserListSaga);

  yield takeLatest(
    actionTypes.CHANGE_CURRENT_PAGE_SUBUSER,
    function* changePageSaga(action) {
      const { clientsSortFieldSubuser, clientsSortOrderSubuser } = yield select(
        (state) => state.manageClients
      );
      const { clientID, page, length, accessToken } = action.payload;
      if (clientID) {
        yield put(
          actions.getClientSubuserList(
            clientID,
            clientsSortFieldSubuser,
            clientsSortOrderSubuser,
            page,
            length,
            accessToken
          )
        );
      }
    }
  );

  yield takeLatest(actionTypes.CHANGE_SORT_SUBUSER, function* changeSortSaga(
    action
  ) {
    let { clientID, field, order, accessToken } = action.payload;
    let { currentPageSubuser, maxSubuserPerPage } = yield select(
      (state) => state.manageClients
    );
    if (clientID) {
      yield put(
        actions.getClientSubuserList(
          clientID,
          field,
          order,
          currentPageSubuser,
          maxSubuserPerPage,
          accessToken
        )
      );
    }
  });
  yield takeLatest(actionTypes.RESEND_EMAIL, resendEmailSaga);
  yield takeLatest(actionTypes.LOGIN_AS_CLIENT, loginAsClientSaga);
  yield takeLatest(actionTypes.SAVE_SUBUSER_DETAILS, saveSubUserDetailsSaga);
  yield takeLatest(actionTypes.EDIT_SUBUSER_DETAILS, editSubUserDetailsSaga);
  yield takeLatest(actionTypes.USER_TYPE_DATA, userTypeDataSaga);
  yield takeLatest(
    actionTypes.SAVE_UPDATED_SUBUSER_DETAILS,
    saveUpdatedSubUserDetailsSaga
  );
  yield takeLatest(
    actionTypes.GET_CLIENT_ACTIVITY_LOGS,
    getClientActivityListSaga
  );
  yield takeLatest(
    actionTypes.GET_CLIENT_ACTIVITY_FILTER,
    getClientActivityFilterSaga
  );
  yield takeLatest(actionTypes.GET_ANOUNCEMENT_DATA, getAnouncementSaga);
  yield takeLatest(
    actionTypes.UPDATE_ANOUNCEMENT_DATA,
    updateAnnouncementDataSaga
  );
  yield takeLatest(
    actionTypes.CHANGE_CURRENT_PAGE_ACTIVITY,
    function* changePageSaga(action) {
      const {
        clientsSortFieldActivity,
        clientsSortOrderActivity,
        clientActivityUpdatedBy,
        clientActivityFunction,
        clientActivityStartDate,
        clientActivityEndDate,
      } = yield select((state) => state.manageClients);
      const { clientID, page, length, accessToken } = action.payload;
      if (clientID) {
        yield put(
          actions.getClientActivityLogsList(
            clientID,
            clientActivityUpdatedBy,
            clientActivityFunction,
            clientActivityStartDate,
            clientActivityEndDate,
            clientsSortFieldActivity,
            clientsSortOrderActivity,
            page,
            length,
            accessToken
          )
        );
      }
    }
  );

  yield takeLatest(actionTypes.CHANGE_SORT_ACTIVITY, function* changeSortSaga(
    action
  ) {
    let {
      clientID,
      field,
      order,
      accessToken,
      clientActivityUpdatedBy,
      clientActivityFunction,
      clientActivityStartDate,
      clientActivityEndDate,
    } = action.payload;
    let { currentPageActivity, maxActivityPerPage } = yield select(
      (state) => state.manageClients
    );
    if (clientID) {
      yield put(
        actions.getClientActivityLogsList(
          clientID,
          clientActivityUpdatedBy,
          clientActivityFunction,
          clientActivityStartDate,
          clientActivityEndDate,
          field,
          order,
          currentPageActivity,
          maxActivityPerPage,
          accessToken
        )
      );
    }
  });
  yield takeLatest(actionTypes.REOPEN_FEEDBACK, function* feedbackReopenSaga(
    action
  ) {
    const { clientID, userId, accessToken } = action.payload;
    const {
      currentPageSubuser,
      maxSubuserPerPage,
      clientsSortFieldSubuser,
      clientsSortOrderSubuser,
    } = yield select((state) => state.manageClients);
    if (clientID) {
      yield put(feedbackActions.updateFeedbackReopen(userId, accessToken, false));
      yield delay(500);
      yield put(
        actions.getClientSubuserList(
          clientID,
          clientsSortFieldSubuser,
          clientsSortOrderSubuser,
          currentPageSubuser,
          maxSubuserPerPage,
          accessToken
        )
      );
    }
  });
}

function* getClientsListSaga(action) {
  let { page, length, accessToken, sortField, sortOrder } = action.payload;
  yield put(actions.setListLoading(true));
  const { clientsFilterData } = yield select((state) => state.manageClients);
  const filterParams = {
    start: page * length,
    length: length,
  };

  const sortParams = `&columns[0][data]=name&columns[0][name]=&columns[0][searchable]=true&columns[0][orderable]=false&columns[0][search][value]=${
    clientsFilterData.name
  }&columns[0][search][regex]=false&columns[1][data]=${sortField}&columns[1][name]=&columns[1][searchable]=false&columns[1][orderable]=true&columns[1][search][value]=&columns[1][search][regex]=false&columns[2][data]=company_name&columns[2][name]=&columns[2][searchable]=true&columns[2][orderable]=false&columns[2][search][value]=${
    clientsFilterData.company_name
  }&columns[2][search][regex]=false&columns[3][data]=email&columns[3][name]=&columns[3][searchable]=true&columns[3][orderable]=false&columns[3][search][value]=${
    clientsFilterData.email
  }&columns[3][search][regex]=false&columns[4][data]=status&columns[4][name]=&columns[4][searchable]=true&columns[4][orderable]=false&columns[4][search][value]=${
    clientsFilterData.status === null ? "" : clientsFilterData.status
  }&columns[4][search][regex]=false&columns[5][data]=is_sandbox&columns[5][name]=&columns[5][searchable]=true&columns[5][orderable]=false&columns[5][search][value]=${
    clientsFilterData.is_sandbox === null ? "" : clientsFilterData.is_sandbox
  }&columns[5][search][regex]=false&columns[6][data]=is_archieve&columns[6][name]=&columns[6][searchable]=true&columns[6][orderable]=false&columns[6][search][value]=${
    clientsFilterData.is_archieve === null ? "" : clientsFilterData.is_archieve
  }&columns[6][search][regex]=false&order[0][column]=1&order[0][dir]=${sortOrder}`;

  try {
    const response = yield call(
      getApiToken,
      apiUrl("CLIENT_LIST_URL"),
      `${queryString.stringify(filterParams)}${sortParams}`,
      accessToken
    );
    if (response.status === 200) {
      let responseData = yield call([response, response.json]);
      if (responseData && responseData.data && responseData.data.dataTable) {
        yield all([
          put(actions.setClientsList(responseData.data.dataTable)),
          put(actions.setListLoading(false)),
        ]);
      } else {
        yield all([
          put(actions.clearList()),
          put(actions.setListLoading(false)),
        ]);
      }
    } else if (response.status === 403) {
      let errorData = yield call([response, response.json]);
      if (errorData && errorData.statusCode && errorData.statusCode === 403) {
        yield put(authActions.setShouldLogout(true));
      } else {
        yield put(actions.setListLoading(false));
        yield put(
          generalActions.addToast(
            "Clients List",
            "You do not have permission to perform this action!",
            "error",
            uid()
          )
        );
      }
    } else {
      yield put(actions.setListLoading(false));
      yield put(
        generalActions.addToast(
          "Clients List",
          "Something went wrong!",
          "error",
          uid()
        )
      );
    }
  } catch (error) {
    yield put(actions.setListLoading(false));
    yield put(
      generalActions.addToast(
        "Clients List",
        "Something went wrong!",
        "error",
        uid()
      )
    );
  }
}

function* saveClientSaga(action) {
  let { clientData, accessToken } = action.payload;
  // let modifyPhone = clientData.phone.slice(clientData.phone_code.length);
  // clientData["phone"] = modifyPhone;
  clientData["is_allow_free_sample_design"] = clientData[
    "is_allow_free_sample_design"
  ]
    ? 1
    : 0;
  clientData["is_all_tab_prioritisation_enabled"] = clientData[
    "is_all_tab_prioritisation_enabled"
  ]
    ? 1
    : 0;
  try {
    const response = yield call(
      postApiToken,
      apiUrl("CREATE_CLIENT_URL"),
      accessToken,
      queryString.stringify(clientData)
    );
    if (response.status === 200) {
      yield all([
        put(actions.saveClientDataLoading(false)),
        put(actions.saveClientDataSuccess(true)),
        put(actions.saveClientDataFailed(false)),
      ]);
      yield put(
        generalActions.addToast(
          "Create Client",
          "Client saved successfully!",
          "success",
          uid()
        )
      );
    } else if (response.status === 403) {
      let errorData = yield call([response, response.json]);
      if (errorData && errorData.statusCode && errorData.statusCode === 403) {
        yield put(authActions.setShouldLogout(true));
      } else {
        yield all([put(actions.saveClientDataLoading(false))]);
        yield put(
          generalActions.addToast(
            "Create Client",
            "You do not have permission to perform this action!",
            "error",
            uid()
          )
        );
      }
    } else {
      let errorData = yield call([response, response.json]);
      yield all([put(actions.saveClientDataLoading(false))]);
      yield put(
        generalActions.addToast(
          "Create Client",
          errorData.message,
          "error",
          uid()
        )
      );
      yield all([
        put(actions.saveClientDataLoading(false)),
        put(actions.saveClientDataSuccess(false)),
        put(actions.saveClientDataFailed(true)),
      ]);
    }
  } catch (error) {
    console.log(error);
    yield all([
      put(actions.saveClientDataLoading(false)),
      put(actions.saveClientDataSuccess(false)),
      put(actions.saveClientDataFailed(true)),
    ]);
  }
}

function* getClientDataSaga(action) {
  let { id, accessToken } = action.payload;
  yield all([
    put(actions.getClientLoading(true)),
    put(actions.getClientSuccess(false)),
    put(actions.getClientFailed(false)),
  ]);
  try {
    const response = yield call(
      getApiToken,
      `${apiUrl("GET_CLIENT_URL")}/${id}`,
      null,
      accessToken
    );
    if (response.status === 200) {
      const responseData = yield call([response, response.json]);
      if (responseData && responseData.data) {
        yield all([
          put(actions.setClientName(responseData.data.name)),
          put(actions.changeClientData(responseData.data)),
          put(actions.getClientLoading(false)),
          put(actions.getClientSuccess(true)),
          put(actions.getClientFailed(false)),
        ]);
      } else {
        yield all([
          put(actions.getClientLoading(false)),
          put(actions.getClientSuccess(false)),
          put(actions.getClientFailed(true)),
        ]);
      }
    } else if (response.status === 403) {
      let errorData = yield call([response, response.json]);
      if (errorData && errorData.statusCode && errorData.statusCode === 403) {
        yield put(authActions.setShouldLogout(true));
      } else {
        yield all([put(actions.getClientLoading(false))]);
        yield put(
          generalActions.addToast(
            "Get Client",
            "You do not have permission to perform this action!",
            "error",
            uid()
          )
        );
      }
    } else {
      yield all([
        put(actions.getClientLoading(false)),
        put(actions.getClientSuccess(false)),
        put(actions.getClientFailed(true)),
      ]);
    }
  } catch (error) {
    console.log(error);
    yield all([
      put(actions.getClientLoading(false)),
      put(actions.getClientSuccess(false)),
      put(actions.getClientFailed(true)),
    ]);
  }
}

function* updateClientDataSaga(action) {
  let { clientID, clientData, accessToken } = action.payload;
  clientData["is_allow_free_sample_design"] = clientData[
    "is_allow_free_sample_design"
  ]
    ? 1
    : 0;
  clientData["is_all_tab_prioritisation_enabled"] = clientData[
    "is_all_tab_prioritisation_enabled"
  ]
    ? 1
    : 0;
  try {
    const response = yield call(
      putApiToken,
      `${apiUrl("UPDATE_CLIENT_URL")}/${clientID}`,
      queryString.stringify(clientData),
      accessToken
    );
    if (response.status === 200) {
      const responseData = yield call([response, response.json]);
      if (responseData && responseData.data) {
        yield all([
          put(actions.setClientName(responseData.data.name)),
          put(actions.updateClientDataLoading(false)),
          put(actions.updateClientDataSuccess(true)),
          put(actions.updateClientDataFailed(false)),
          put(actions.getClientData(clientID, accessToken)),
        ]);
        yield put(
          generalActions.addToast(
            "Update Client",
            "Client updated successfully!",
            "success",
            uid()
          )
        );
      }
    } else if (response.status === 403) {
      let errorData = yield call([response, response.json]);
      if (errorData && errorData.statusCode && errorData.statusCode === 403) {
        yield put(authActions.setShouldLogout(true));
      } else {
        yield all([put(actions.saveClientDataLoading(false))]);
        yield put(
          generalActions.addToast(
            "Update Client",
            "You do not have permission to perform this action!",
            "error",
            uid()
          )
        );
      }
    } else {
      yield all([put(actions.saveClientDataLoading(false))]);
      let errorData = yield call([response, response.json]);
      yield put(
        generalActions.addToast(
          "Update Client",
          errorData.message,
          "error",
          uid()
        )
      );
      yield all([
        put(actions.updateClientDataLoading(false)),
        put(actions.updateClientDataSuccess(false)),
        put(actions.updateClientDataFailed(true)),
      ]);
    }
  } catch (error) {
    console.log(error);
    yield all([
      put(actions.updateClientDataLoading(false)),
      put(actions.updateClientDataSuccess(false)),
      put(actions.updateClientDataFailed(true)),
    ]);
  }
}

function* getClientApiAccessKeySaga(action) {
  let { clientID, accessToken } = action.payload;
  yield all([
    put(actions.getClientApiLoading(true)),
    put(actions.getClientApiSuccess(false)),
    put(actions.getClientApiFailed(false)),
  ]);
  let tempData = { client_id: clientID };
  try {
    const response = yield call(
      postApiToken,
      apiUrl("GET_CLIENT_API_ACCESS_KEY_URL"),
      accessToken,
      queryString.stringify(tempData)
    );

    if (response.status === 200) {
      const responseData = yield call([response, response.json]);
      if (responseData && responseData.data) {
        yield all([
          put(actions.setClientApiAccessKey(responseData.data.accessToken)),
          put(actions.getClientApiLoading(false)),
          put(actions.getClientApiSuccess(true)),
          put(actions.getClientApiFailed(false)),
        ]);
      } else {
        yield all([
          put(actions.getClientApiApiLoading(false)),
          put(actions.getClientApiSuccess(false)),
          put(actions.getClientApiFailed(true)),
        ]);
      }
    } else if (response.status === 403) {
      let errorData = yield call([response, response.json]);
      if (errorData && errorData.statusCode && errorData.statusCode === 403) {
        yield put(authActions.setShouldLogout(true));
      } else {
        yield all([put(actions.getClientApiLoading(false))]);
        yield put(
          generalActions.addToast(
            "Get Client Access Key",
            "You do not have permission to perform this action!",
            "error",
            uid()
          )
        );
      }
    } else {
      yield all([
        put(actions.getClientApiLoading(false)),
        put(actions.getClientApiSuccess(false)),
        put(actions.getClientApiFailed(true)),
      ]);
    }
  } catch (error) {
    console.log(error);
    yield all([
      put(actions.getClientApiLoading(false)),
      put(actions.getClientApiSuccess(false)),
      put(actions.getClientApiFailed(true)),
    ]);
  }
}

function* regenerateApiAccessKeySaga(action) {
  let { clientID, accessToken } = action.payload;
  yield all([
    put(actions.getClientApiLoading(true)),
    put(actions.getClientApiSuccess(false)),
    put(actions.getClientApiFailed(false)),
  ]);
  let tempData = { client_id: clientID };
  try {
    const response = yield call(
      postApiToken,
      apiUrl("REGENERATE_CLIENT_API_ACCESS_KEY_URL"),
      accessToken,
      queryString.stringify(tempData)
    );

    if (response.status === 200) {
      const responseData = yield call([response, response.json]);
      if (responseData && responseData.data) {
        yield all([
          put(actions.setClientApiAccessKey(responseData.data.accessToken)),
          put(actions.getClientApiLoading(false)),
          put(actions.getClientApiSuccess(true)),
          put(actions.getClientApiFailed(false)),
        ]);
      } else {
        yield all([
          put(actions.getClientApiApiLoading(false)),
          put(actions.getClientApiSuccess(false)),
          put(actions.getClientApiFailed(true)),
        ]);
      }
    } else if (response.status === 403) {
      let errorData = yield call([response, response.json]);
      if (errorData && errorData.statusCode && errorData.statusCode === 403) {
        yield put(authActions.setShouldLogout(true));
      } else {
        yield all([put(actions.getClientApiLoading(false))]);
        yield put(
          generalActions.addToast(
            "Regenerate Client Access Key",
            "You do not have permission to perform this action!",
            "error",
            uid()
          )
        );
      }
    } else {
      yield all([
        put(actions.getClientApiLoading(false)),
        put(actions.getClientApiSuccess(false)),
        put(actions.getClientApiFailed(true)),
      ]);
    }
  } catch (error) {
    console.log(error);
    yield all([
      put(actions.getClientApiLoading(false)),
      put(actions.getClientApiSuccess(false)),
      put(actions.getClientApiFailed(true)),
    ]);
  }
}

function* resourceSearchSaga(action) {
  let { searchTerm, accessToken } = action.payload;
  yield all([
    put(actions.getClientApiLoading(true)),
    put(actions.getClientApiSuccess(false)),
    put(actions.getClientApiFailed(false)),
  ]);
  let tempData = { "search-term": searchTerm };
  try {
    const response = yield call(
      postApiToken,
      apiUrl("CLIENT_RESOURCE_SEARCH_URL"),
      accessToken,
      queryString.stringify(tempData)
    );

    if (response.status === 200) {
      const responseData = yield call([response, response.json]);
      if (responseData && responseData.data) {
        let tempArr = [];
        let tempArri = [];
        for (let [key, value] of Object.entries(responseData.data)) {
          tempArr.push({ id: key, name: value });
          tempArri.push(value);
        }
        yield all([
          put(actions.setResourceSuggession(tempArr, tempArri)),
          put(actions.getClientApiLoading(false)),
          put(actions.getClientApiSuccess(true)),
          put(actions.getClientApiFailed(false)),
        ]);
      } else {
        yield all([
          put(actions.getClientApiApiLoading(false)),
          put(actions.getClientApiSuccess(false)),
          put(actions.getClientApiFailed(true)),
        ]);
      }
    } else if (response.status === 403) {
      let errorData = yield call([response, response.json]);
      if (errorData && errorData.statusCode && errorData.statusCode === 403) {
        yield put(authActions.setShouldLogout(true));
      } else {
        yield all([put(actions.getClientApiLoading(false))]);
        yield put(
          generalActions.addToast(
            "Search Resource",
            "You do not have permission to perform this action!",
            "error",
            uid()
          )
        );
      }
    } else {
      yield all([
        put(actions.setResourceSuggession([], [])),
        put(actions.getClientApiLoading(false)),
        put(actions.getClientApiSuccess(false)),
        put(actions.getClientApiFailed(true)),
      ]);
    }
  } catch (error) {
    console.log(error);
    yield all([
      put(actions.getClientApiLoading(false)),
      put(actions.getClientApiSuccess(false)),
      put(actions.getClientApiFailed(true)),
    ]);
  }
}

function* addClientResourceSaga(action) {
  let { clientID, designerID, isPM, accessToken } = action.payload;
  yield all([
    put(actions.addClientResourceLoading(true)),
    put(actions.addClientResourceSuccess(false)),
    put(actions.addClientResourceFailed(false)),
  ]);
  let tempData = { client_id: clientID, designer_id: designerID, is_pm: isPM };
  try {
    const response = yield call(
      postApiToken,
      apiUrl("ADD_CLIENT_RESOURCE_URL"),
      accessToken,
      queryString.stringify(tempData)
    );
    let { clientsSortFieldResource, clientsSortOrderResource } = yield select(
      (state) => state.manageClients
    );
    if (response.status === 200) {
      const responseData = yield call([response, response.json]);
      if (responseData && responseData.data) {
        yield all([
          put(actions.setResourceSuggession([], [])),
          put(
            actions.getClientResourceList(
              clientID,
              clientsSortFieldResource,
              clientsSortOrderResource,
              0,
              10,
              accessToken
            )
          ),
          put(actions.addClientResourceLoading(false)),
          put(actions.addClientResourceSuccess(true)),
          put(actions.addClientResourceFailed(false)),
        ]);
      } else {
        yield all([
          put(actions.getClientApiApiLoading(false)),
          put(actions.addClientResourceSuccess(false)),
          put(actions.addClientResourceFailed(true)),
        ]);
      }
    } else if (response.status === 403) {
      let errorData = yield call([response, response.json]);
      if (errorData && errorData.statusCode && errorData.statusCode === 403) {
        yield put(authActions.setShouldLogout(true));
      } else {
        yield all([put(actions.addClientResourceLoading(false))]);
        yield put(
          generalActions.addToast(
            "Add client resource",
            "You do not have permission to perform this action!",
            "error",
            uid()
          )
        );
      }
    } else {
      let errorData = yield call([response, response.json]);
      yield put(
        generalActions.addToast(
          "Add client resource",
          errorData.message,
          "error",
          uid()
        )
      );
      yield all([
        put(actions.setResourceSuggession([], [])),
        put(actions.addClientResourceLoading(false)),
        put(actions.addClientResourceSuccess(false)),
        put(actions.addClientResourceFailed(true)),
      ]);
    }
  } catch (error) {
    console.log(error);
    yield all([
      put(actions.addClientResourceLoading(false)),
      put(actions.addClientResourceSuccess(false)),
      put(actions.addClientResourceFailed(true)),
    ]);
  }
}

function* getClientResourceListSaga(action) {
  let {
    clientID,
    page,
    length,
    accessToken,
    sortField,
    sortOrder,
  } = action.payload;

  const filterParams = {
    client_id: clientID,
    start: page * length,
    length: length,
  };
  const sortParams = `&columns[0][data]= &columns[0][name]= &columns[0][searchable]= false&columns[0][orderable]= false&columns[0][search][value]= &columns[0][search][regex]= false&columns[1][data]= ${sortField}&columns[1][name]= &columns[1][searchable]= false&columns[1][orderable]= true&columns[1][search][value]= &columns[1][search][regex]= false&order[0][column]= 1&order[0][dir]= ${sortOrder}`;

  try {
    yield put(actions.getClientResourceLoading(true));
    const response = yield call(
      getApiToken,
      apiUrl("GET_CLIENT_RESOURCE_URL"),
      `${queryString.stringify(filterParams)}${sortParams}`,
      accessToken
    );
    if (response.status === 200) {
      let responseData = yield call([response, response.json]);
      if (responseData && responseData.data && responseData.data.dataTable) {
        yield all([
          put(actions.setClientResourceList(responseData.data.dataTable)),
          put(actions.getClientResourceLoading(false)),
        ]);
      } else {
        yield all([
          put(actions.clearList()),
          put(actions.getClientResourceLoading(false)),
        ]);
      }
    } else if (response.status === 403) {
      let errorData = yield call([response, response.json]);
      if (errorData && errorData.statusCode && errorData.statusCode === 403) {
        yield put(authActions.setShouldLogout(true));
      } else {
        yield put(actions.getClientResourceLoading(false));
        yield put(
          generalActions.addToast(
            "Client Resource List",
            "You do not have permission to perform this action!",
            "error",
            uid()
          )
        );
      }
    } else {
      yield put(actions.getClientResourceLoading(false));
      yield put(
        generalActions.addToast(
          "Client Resource List",
          "Something went wrong!",
          "error",
          uid()
        )
      );
    }
  } catch (error) {
    console.log(error);
    yield put(actions.getClientResourceLoading(false));
    yield put(
      generalActions.addToast(
        "Client Resource List",
        "Something went wrong!",
        "error",
        uid()
      )
    );
  }
}

function* removeClientResourceSaga(action) {
  let { clientID, resourceID, accessToken } = action.payload;
  yield all([put(actions.removeClientResourceLoading(true))]);
  let tempData = { client_id: clientID, designer_id: resourceID };
  try {
    const response = yield call(
      deleteApiToken,
      apiUrl("REMOVE_CLIENT_RESOURCE_URL"),
      accessToken,
      queryString.stringify(tempData)
    );
    let { clientsSortFieldResource, clientsSortOrderResource } = yield select(
      (state) => state.manageClients
    );
    if (response.status === 200) {
      const responseData = yield call([response, response.json]);
      if (responseData && responseData.data) {
        yield all([
          // put(
          //   actions.getClientResourceList(
          //     clientID,
          //     clientsSortFieldResource,
          //     clientsSortOrderResource,
          //     0,
          //     10,
          //     accessToken
          //   )),
          put(actions.changeCurrentPageResource(clientID, 0, 10, accessToken)),
          put(actions.removeClientResourceLoading(false)),
        ]);
      } else {
        yield all([
          put(actions.getClientResourceList()),
          put(actions.removeClientResourceLoading(false)),
        ]);
      }
    } else if (response.status === 403) {
      let errorData = yield call([response, response.json]);
      if (errorData && errorData.statusCode && errorData.statusCode === 403) {
        yield put(authActions.setShouldLogout(true));
      } else {
        yield all([put(actions.removeClientResourceLoading(false))]);
        yield put(
          generalActions.addToast(
            "Remove Client Resource",
            "You do not have permission to perform this action!",
            "error",
            uid()
          )
        );
      }
    } else {
      yield all([put(actions.removeClientResourceLoading(false))]);
    }
  } catch (error) {
    console.log(error);
    yield all([put(actions.removeClientResourceLoading(false))]);
  }
}

function* resourceSetPMSaga(action) {
  let { clientID, resourceID, accessToken } = action.payload;
  yield all([put(actions.setPMClientResourceLoading(true))]);
  let tempData = { client_id: clientID, designer_id: resourceID };
  try {
    const response = yield call(
      postApiToken,
      apiUrl("SET_PM_CLIENT_RESOURCE_URL"),
      accessToken,
      queryString.stringify(tempData)
    );
    let { clientsSortFieldResource, clientsSortOrderResource } = yield select(
      (state) => state.manageClients
    );
    if (response.status === 200) {
      const responseData = yield call([response, response.json]);
      if (responseData && responseData.data) {
        yield all([
          put(
            actions.getClientResourceList(
              clientID,
              clientsSortFieldResource,
              clientsSortOrderResource,
              0,
              10,
              accessToken
            )
          ),
          put(actions.setPMClientResourceLoading(false)),
        ]);
      } else {
        yield all([
          put(actions.getClientResourceList()),
          put(actions.setPMClientResourceLoading(false)),
        ]);
      }
    } else if (response.status === 403) {
      let errorData = yield call([response, response.json]);
      if (errorData && errorData.statusCode && errorData.statusCode === 403) {
        yield put(authActions.setShouldLogout(true));
      } else {
        yield all([put(actions.setPMClientResourceLoading(false))]);
        yield put(
          generalActions.addToast(
            "Remove Client Resource",
            "You do not have permission to perform this action!",
            "error",
            uid()
          )
        );
      }
    } else {
      yield all([put(actions.setPMClientResourceLoading(false))]);
    }
  } catch (error) {
    console.log(error);
    yield all([put(actions.setPMClientResourceLoading(false))]);
  }
}

function* getClientSubuserListSaga(action) {
  let {
    clientID,
    page,
    length,
    accessToken,
    sortField,
    sortOrder,
  } = action.payload;

  const filterParams = {
    client_id: clientID,
    start: page * length,
    length: length,
  };

  const sortParams = `&columns[0][data]= &columns[0][name]= &columns[0][searchable]= false&columns[0][orderable]= false&columns[0][search][value]= &columns[0][search][regex]= false&columns[1][data]= ${sortField}&columns[1][name]= &columns[1][searchable]= false&columns[1][orderable]= true&columns[1][search][value]= &columns[1][search][regex]= false&order[0][column]= 1&order[0][dir]= ${sortOrder}`;

  try {
    yield put(actions.getClientSubuserLoading(true));
    const response = yield call(
      getApiToken,
      apiUrl("GET_CLIENT_SUBUSER_URL"),
      `${queryString.stringify(filterParams)}${sortParams}`,
      accessToken
    );
    if (response.status === 200) {
      let responseData = yield call([response, response.json]);
      if (responseData && responseData.data && responseData.data.dataTable) {
        yield all([
          put(actions.setClientSubuserList(responseData.data.dataTable)),
          put(actions.getClientSubuserLoading(false)),
        ]);
      } else {
        yield all([
          put(actions.clearList()),
          put(actions.getClientSubuserLoading(false)),
        ]);
      }
    } else if (response.status === 403) {
      let errorData = yield call([response, response.json]);
      if (errorData && errorData.statusCode && errorData.statusCode === 403) {
        yield put(authActions.setShouldLogout(true));
      } else {
        yield put(actions.getClientSubuserLoading(false));
        yield put(
          generalActions.addToast(
            "Client Subuser List",
            "You do not have permission to perform this action!",
            "error",
            uid()
          )
        );
      }
    } else {
      yield put(actions.getClientSubuserLoading(false));
      yield put(
        generalActions.addToast(
          "Client Subuser List",
          "Something went wrong!",
          "error",
          uid()
        )
      );
    }
  } catch (error) {
    console.log(error);
    yield put(actions.getClientSubuserLoading(false));
    yield put(
      generalActions.addToast(
        "Client Subuser List",
        "Something went wrong!",
        "error",
        uid()
      )
    );
  }
}

function* resendEmailSaga(action) {
  let { clientID, accessToken } = action.payload;
  try {
    yield put(actions.resendEmailLoading(true));
    const response = yield call(
      getApiToken,
      apiUrl("RESEND_EMAIL_URL"),
      `id=${clientID}`,
      accessToken
    );
    if (response.status === 200) {
      let responseData = yield call([response, response.json]);
      if (responseData && responseData.data) {
        yield all([
          put(
            generalActions.addToast(
              "Client Resend Email",
              "Email send successfully",
              "success",
              uid()
            )
          ),
          put(actions.resendEmailLoading(false)),
        ]);
      } else {
        yield all([put(actions.resendEmailLoading(false))]);
      }
    } else if (response.status === 403) {
      let errorData = yield call([response, response.json]);
      if (errorData && errorData.statusCode && errorData.statusCode === 403) {
        yield put(authActions.setShouldLogout(true));
      } else {
        yield put(actions.resendEmailLoading(false));
        yield put(
          generalActions.addToast(
            "Client Resend Email",
            "You do not have permission to perform this action!",
            "error",
            uid()
          )
        );
      }
    } else {
      yield put(actions.resendEmailLoading(false));
      yield put(
        generalActions.addToast(
          "Client Resend Email",
          "Something went wrong!",
          "error",
          uid()
        )
      );
    }
  } catch (error) {
    console.log(error);
    yield put(actions.resendEmailLoading(false));
    yield put(
      generalActions.addToast(
        "Client Resend Email",
        "Something went wrong!",
        "error",
        uid()
      )
    );
  }
}
function* loginAsClientSaga(action) {
  let { clientID, accessToken } = action.payload;
  try {
    yield put(actions.loginAsClientLoading(true));
    let tempData = { client_id: clientID };
    const response = yield call(
      postApiToken,
      apiUrl("LOGIN_AS_CLIENT_URL"),
      accessToken,
      queryString.stringify(tempData)
    );
    if (response.status === 200) {
      let responseData = yield call([response, response.json]);
      if (responseData && responseData.data && responseData.data.accessToken) {
        yield all([
          put(actions.setAccessToken(responseData.data.accessToken)),
          put(actions.loginAsClientLoading(false)),
          put(actions.loginAsClientFailed(false)),
          put(actions.loginAsClientSuccess(true)),
        ]);
      } else {
        yield all([
          put(actions.loginAsClientLoading(false)),
          put(actions.loginAsClientSuccess(false)),
          put(actions.loginAsClientFailed(true)),
        ]);
      }
    } else if (response.status === 403) {
      let errorData = yield call([response, response.json]);
      if (errorData && errorData.statusCode && errorData.statusCode === 403) {
        yield put(authActions.setShouldLogout(true));
      } else {
        yield all([
          put(actions.getClientApiLoading(false)),
          put(actions.loginAsClientSuccess(false)),
          put(actions.loginAsClientFailed(true)),
        ]);
        yield put(
          generalActions.addToast(
            "Login As Client",
            errorData.message,
            "error",
            uid()
          )
        );
      }
    } else if (response.status === 422) {
      let errorData = yield call([response, response.json]);
      if (errorData && errorData.message) {
        yield all([
          put(actions.getClientApiLoading(false)),
          put(actions.loginAsClientSuccess(false)),
          put(actions.loginAsClientFailed(true)),
        ]);
        yield put(
          generalActions.addToast(
            "Login As Client",
            errorData.message,
            "error",
            uid()
          )
        );
      }
    } else {
      yield all([
        put(actions.getClientApiLoading(false)),
        put(actions.loginAsClientSuccess(false)),
        put(actions.loginAsClientFailed(true)),
      ]);
      yield put(
        generalActions.addToast(
          "Login As Client",
          "Something went wrong",
          "error",
          uid()
        )
      );
    }
  } catch (error) {
    yield all([
      put(actions.getClientApiLoading(false)),
      put(actions.loginAsClientSuccess(false)),
      put(actions.loginAsClientFailed(true)),
    ]);
  }
}

function* saveSubUserDetailsSaga(action) {
  let {
    clientID,
    name,
    email,
    phone_code,
    phone,
    userType,
    accessToken,
    is_request_prioritisation_allowed,
  } = action.payload;
  let tempData = {
    client_id: clientID,
    name: name,
    email: email,
    phone_code: phone_code,
    phone: phone,
    group_id: userType,
    is_request_prioritisation_allowed: is_request_prioritisation_allowed
      ? 1
      : 0,
  };
  try {
    yield put({
      type: actionTypes.SAVE_SUBUSER_DETAILS_LOADING,
    });
    const response = yield call(
      postApiToken,
      apiUrl("SAVE_SUBUSER_DATA_URL"),
      accessToken,
      queryString.stringify(tempData)
    );
    if (response.status === 200) {
      const responseData = yield call([response, response.json]);
      if (responseData && responseData.data) {
        yield put({
          type: actionTypes.SAVE_SUBUSER_DETAILS_SUCCESS,
          payload: response?.data?.data,
        });
      } else {
        yield put({
          type: actionTypes.SAVE_SUBUSER_DETAILS_FAILURE,
          payload: response?.data?.message,
        });
      }
    } else if (response.status === 403) {
      let errorData = yield call([response, response.json]);
      if (errorData && errorData.statusCode && errorData.statusCode === 403) {
        yield put(
          generalActions.addToast(
            "Save Subuser",
            errorData.message,
            "error",
            uid()
          )
        );
      }
    } else {
      let errorData = yield call([response, response.json]);
      yield put(
        generalActions.addToast(
          "Save Subuser",
          errorData.message,
          "error",
          uid()
        )
      );
      yield put({
        type: actionTypes.SAVE_SUBUSER_DETAILS_FAILURE,
        payload: errorData,
      });
    }
  } catch (error) {
    yield put({
      type: actionTypes.SAVE_SUBUSER_DETAILS_FAILURE,
      payload: error?.response?.data?.message,
    });
  }
}

function* editSubUserDetailsSaga(action) {
  let { id, accessToken } = action.payload;
  try {
    yield put({
      type: actionTypes.EDIT_SUBUSER_DETAILS_LOADING,
    });
    const response = yield call(
      getApiToken,
      `${apiUrl("EDIT_SUBUSER_DATA_URL")}/${id}`,
      null,
      accessToken
    );
    if (response.status === 200) {
      const responseData = yield call([response, response.json]);
      if (responseData && responseData.data) {
        yield put({
          type: actionTypes.EDIT_SUBUSER_DETAILS_SUCCESS,
          payload: responseData,
        });
      } else {
        yield put({
          type: actionTypes.EDIT_SUBUSER_DETAILS_FAILURE,
          payload: response?.data?.message,
        });
      }
    } else if (response.status === 403) {
      let errorData = yield call([response, response.json]);
      if (errorData && errorData.statusCode && errorData.statusCode === 403) {
        yield put(
          generalActions.addToast(
            "Edit Subuser",
            errorData.message,
            "error",
            uid()
          )
        );
      }
    } else {
      let errorData = yield call([response, response.json]);
      yield put(
        generalActions.addToast(
          "Edit Subuser",
          "Something went wrong",
          "error",
          uid()
        )
      );
      yield put({
        type: actionTypes.EDIT_SUBUSER_DETAILS_FAILURE,
        payload: errorData?.message,
      });
    }
  } catch (error) {
    yield put({
      type: actionTypes.EDIT_SUBUSER_DETAILS_FAILURE,
      payload: error?.response?.data?.message,
    });
  }
}

function* saveUpdatedSubUserDetailsSaga(action) {
  let {
    id,
    name,
    email,
    phone_code,
    phone,
    status,
    userType,
    accessToken,
    is_request_prioritisation_allowed,
  } = action.payload;
  let tempData = {
    name: name,
    email: email,
    phone_code: phone_code,
    phone: phone,
    status: status,
    group_id: userType,
    is_request_prioritisation_allowed: is_request_prioritisation_allowed
      ? 1
      : 0,
  };
  try {
    yield put({
      type: actionTypes.SAVE_UPDATED_DETAILS_SUBUSER_LOADING,
    });
    const response = yield call(
      putApiToken,
      `${apiUrl("SAVE_UPDATED_SUBUSER_DATA_URL")}/${id}`,
      queryString.stringify(tempData),
      accessToken
    );
    if (response.status === 200) {
      const responseData = yield call([response, response.json]);
      if (responseData && responseData.data) {
        yield put({
          type: actionTypes.SAVE_UPDATED_DETAILS_SUBUSER_SUCCESS,
          payload: response?.data?.data,
        });
      } else {
        yield put({
          type: actionTypes.SAVE_UPDATED_DETAILS_SUBUSER_FAILURE,
          payload: response?.data?.message,
        });
      }
    } else if (response.status === 403) {
      let errorData = yield call([response, response.json]);
      if (errorData && errorData.statusCode && errorData.statusCode === 403) {
        yield put(
          generalActions.addToast(
            "Save Subuser",
            errorData.message,
            "error",
            uid()
          )
        );
      }
    } else {
      let errorData = yield call([response, response.json]);
      yield put(
        generalActions.addToast(
          "Save Subuser",
          "Something went wrong",
          "error",
          uid()
        )
      );
      yield put({
        type: actionTypes.SAVE_UPDATED_DETAILS_SUBUSER_FAILURE,
        payload: errorData?.message,
      });
    }
  } catch (error) {
    yield put({
      type: actionTypes.SAVE_UPDATED_DETAILS_SUBUSER_FAILURE,
      payload: error?.response?.data?.message,
    });
  }
}

function* userTypeDataSaga(action) {
  let { accessToken } = action.payload;
  try {
    yield put({
      type: actionTypes.USER_TYPE_DATA_LOADING,
    });
    const response = yield call(
      getApiToken,
      apiUrl("USER_TYPE_DATA_URL"),
      null,
      accessToken
    );
    if (response.status === 200) {
      const responseData = yield call([response, response.json]);
      if (responseData && responseData.data) {
        yield put({
          type: actionTypes.USER_TYPE_DATA_SUCCESS,
          payload: responseData,
        });
      } else {
        yield put({
          type: actionTypes.USER_TYPE_DATA_FAILURE,
          payload: response?.data?.message,
        });
      }
    } else if (response.status === 403) {
      let errorData = yield call([response, response.json]);
      if (errorData && errorData.statusCode && errorData.statusCode === 403) {
        yield put(
          generalActions.addToast(
            "Subuser type data",
            errorData.message,
            "error",
            uid()
          )
        );
      }
    } else {
      let errorData = yield call([response, response.json]);
      yield put(
        generalActions.addToast(
          "Subuser type data",
          "Something went wrong",
          "error",
          uid()
        )
      );
      yield put({
        type: actionTypes.USER_TYPE_DATA_FAILURE,
        payload: errorData?.message,
      });
    }
  } catch (error) {
    yield put({
      type: actionTypes.USER_TYPE_DATA_FAILURE,
      payload: error?.response?.data?.message,
    });
  }
}

function* getClientActivityListSaga(action) {
  let {
    clientID,
    page,
    length,
    accessToken,
    sortField,
    sortOrder,
    clientActivityUpdatedBy,
    clientActivityFunction,
    clientActivityStartDate,
    clientActivityEndDate,
  } = action.payload;

  let filterParams = {
    client_id: clientID,
    start: page * length,
    length: length,
  };

  const getFormattedDate = (d) => {
    var date = new Date(d),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  };

  if (clientActivityUpdatedBy.length) {
    let temp = [];
    clientActivityUpdatedBy.forEach(function(v) {
      temp.push(v.value);
    });
    filterParams["updated_by"] = temp.toString();
  }
  if (clientActivityFunction.length) {
    let temp = [];
    clientActivityFunction.forEach(function(v) {
      temp.push(v.value);
    });
    filterParams["function"] = temp.toString();
  }
  if (clientActivityStartDate) {
    filterParams["date_from"] = getFormattedDate(clientActivityStartDate);
  }
  if (clientActivityEndDate) {
    filterParams["date_to"] = getFormattedDate(clientActivityEndDate);
  }

  const sortParams = `&columns[0][data]= &columns[0][name]= &columns[0][searchable]= false&columns[0][orderable]= false&columns[0][search][value]= &columns[0][search][regex]= false&columns[1][data]= ${sortField}&columns[1][name]= &columns[1][searchable]= false&columns[1][orderable]= true&columns[1][search][value]= &columns[1][search][regex]= false&order[0][column]= 1&order[0][dir]= ${sortOrder}`;

  try {
    yield put(actions.getClientActivityLoading(true));
    const response = yield call(
      getApiToken,
      apiUrl("GET_CLIENT_ACTIVITY_URL"),
      `${queryString.stringify(filterParams)}${sortParams}`,
      accessToken
    );
    if (response.status === 200) {
      let responseData = yield call([response, response.json]);
      if (responseData && responseData.data && responseData.data.dataTable) {
        yield all([
          put(actions.setClientActivityList(responseData.data.dataTable)),
          put(actions.getClientActivityLoading(false)),
        ]);
      } else {
        yield all([
          put(actions.clearList()),
          put(actions.getClientActivityLoading(false)),
        ]);
      }
    } else if (response.status === 403) {
      let errorData = yield call([response, response.json]);
      if (errorData && errorData.statusCode && errorData.statusCode === 403) {
        yield put(authActions.setShouldLogout(true));
      } else {
        yield put(actions.getClientActivityLoading(false));
        yield put(
          generalActions.addToast(
            "Client Activity List",
            "You do not have permission to perform this action!",
            "error",
            uid()
          )
        );
      }
    } else {
      yield put(actions.getClientActivityLoading(false));
      yield put(
        generalActions.addToast(
          "Client Activity List",
          "Something went wrong!",
          "error",
          uid()
        )
      );
    }
  } catch (error) {
    console.log(error);
    yield put(actions.getClientActivityLoading(false));
    yield put(
      generalActions.addToast(
        "Client Activity List",
        "Something went wrong!",
        "error",
        uid()
      )
    );
  }
}

function* getClientActivityFilterSaga(action) {
  let { accessToken } = action.payload;

  try {
    const response = yield call(
      getApiToken,
      apiUrl("GET_CLIENT_ACTIVITY_FILTER_URL"),
      null,
      accessToken
    );
    if (response.status === 200) {
      const responseData = yield call([response, response.json]);
      yield all([put(actions.setClientActivityFilter(responseData.data))]);
    } else if (response.status === 403) {
      let errorData = yield call([response, response.json]);
      if (errorData && errorData.statusCode && errorData.statusCode === 403) {
        yield put(
          generalActions.addToast(
            "Save Subuser",
            errorData.message,
            "error",
            uid()
          )
        );
      }
    } else {
      let errorData = yield call([response, response.json]);
      yield put(
        generalActions.addToast(
          "Save Subuser",
          errorData.message,
          "error",
          uid()
        )
      );
    }
  } catch (error) {}
}

function* getAnouncementSaga(action) {
  let { accessToken } = action.payload;
  try {
    const response = yield call(
      getApiToken,
      apiUrl("GET_ANOUNCEMENT_URL"),
      null,
      accessToken
    );
    if (response.status === 200) {
      const responseData = yield call([response, response.json]);
      console.log("responseData", responseData);
      let enterpriseTemp = [];
      responseData.data.clients &&
        responseData.data.clients.enterprise.forEach(function(c) {
          responseData.data.announcement.clients.forEach(function(cc) {
            if (cc == c.id) {
              enterpriseTemp.push({ label: c.company_name, value: c.id });
            }
          });
        });

      let standardTemp = [];
      responseData.data.clients &&
        responseData.data.clients.standard.forEach(function(c) {
          responseData.data.announcement.clients.forEach(function(cc) {
            if (cc == c.id) {
              standardTemp.push({ label: c.company_name, value: c.id });
            }
          });
        });

      yield all([
        put(actions.setAnoucementData({ ...responseData.data.announcement })),
        put(actions.setAnoucementClientDropdown(responseData.data.clients)),
        put(actions.setEnterPriseClients(enterpriseTemp)),
        put(actions.setSatndardClients(standardTemp)),
      ]);
    } else if (response.status === 403) {
      let errorData = yield call([response, response.json]);
      if (errorData && errorData.statusCode && errorData.statusCode === 403) {
        yield put(
          generalActions.addToast(
            "Anouncement Data",
            errorData.message,
            "error",
            uid()
          )
        );
      }
    } else {
      let errorData = yield call([response, response.json]);
      yield put(
        generalActions.addToast(
          "Anouncement Data",
          "Something went wrong",
          "error",
          uid()
        )
      );
      yield put({
        type: actionTypes.USER_TYPE_DATA_FAILURE,
        payload: errorData?.message,
      });
    }
  } catch (error) {
    yield put({
      type: actionTypes.USER_TYPE_DATA_FAILURE,
      payload: error?.response?.data?.message,
    });
  }
}

function* updateAnnouncementDataSaga(action) {
  let { data, accessToken } = action.payload;
  if (data.message.replace(/<(.|\n)*?>/g, "").trim().length === 0) {
    yield put(
      generalActions.addToast(
        "Announcement Data Update",
        "The message field is required.",
        "error",
        uid()
      )
    );
    return;
  }
  try {
    const response = yield call(
      postApiToken,
      apiUrl("UPDATE_ANNOUNCEMENT_DATA_URL"),
      accessToken,
      queryString.stringify(data)
    );
    if (response.status === 200) {
      const responseData = yield call([response, response.json]);
      yield all([
        put(actions.saveClientDataLoading(false)),
        put(actions.saveClientDataSuccess(true)),
        put(actions.saveClientDataFailed(false)),
      ]);
      yield put(
        generalActions.addToast(
          "Announcement Data Update",
          responseData.message,
          "success",
          uid()
        )
      );
    } else if (response.status === 403) {
      let errorData = yield call([response, response.json]);
      if (errorData && errorData.statusCode && errorData.statusCode === 403) {
        yield put(authActions.setShouldLogout(true));
      } else {
        yield all([put(actions.saveClientDataLoading(false))]);
        yield put(
          generalActions.addToast(
            "Announcement Data Update",
            "You do not have permission to perform this action!",
            "error",
            uid()
          )
        );
      }
    } else {
      let errorData = yield call([response, response.json]);
      yield all([put(actions.saveClientDataLoading(false))]);
      yield put(
        generalActions.addToast(
          "Announcement Data Update",
          errorData.message,
          "error",
          uid()
        )
      );
      yield all([
        put(actions.saveClientDataLoading(false)),
        put(actions.saveClientDataSuccess(false)),
        put(actions.saveClientDataFailed(true)),
      ]);
    }
  } catch (error) {
    console.log(error);
    yield all([
      put(actions.saveClientDataLoading(false)),
      put(actions.saveClientDataSuccess(false)),
      put(actions.saveClientDataFailed(true)),
    ]);
  }
}
